<template>
  <div
    :class="[
      containerClasses,
      fullWidth && 'custom-input-full-width',
      disabled && 'custom-input-disabled',
    ]"
  >
    <label :for="inputId" v-if="inputLabel !== ''" class="custom-input-label">
      {{
        `${inputLabel} ${
          validateRule && validateRule.includes('required') ? '*' : ''
        }`
      }}
    </label>
    <ValidationProvider
      v-if="validateRule"
      :rules="`only-english-characters|${validateRule}`"
      v-slot="{ errors }"
      class="custom-input-container"
      :mode="interactionMode"
    >
      <input
        :type="inputType"
        :placeholder="inputPlaceholder"
        :name="inputName"
        :id="inputId"
        v-model="value"
        class="custom-input-input"
        :class="errors[0] && 'has-errors'"
        @keyup="handleChange(errors)"
        @change="handleChange(errors)"
        ref="customInputRef"
      />
      <AlertIcon v-if="errors[0]" class="custom-input-icon" />
      <component
        v-if="!errors[0] && icon"
        class="custom-input-icon"
        :is="name"
      />
      <component
        v-if="!errors[0] && inputType === 'password'"
        class="custom-input-icon"
        :is="showPassword ? 'PasswordEyeShown' : 'PasswordEyeHidden'"
        @click="handleShowPassword"
      />
      <span class="custom-input-error">
        {{ $t(errors[0]) }}
      </span>
    </ValidationProvider>
    <ValidationProvider
      v-else
      rules="only-english-characters"
      v-slot="{ errors }"
      class="custom-input-container"
      :mode="interactionMode"
    >
      <AlertIcon v-if="errors[0]" class="custom-input-icon" />
      <input
        :type="inputType"
        :placeholder="inputPlaceholder"
        :name="inputName"
        :id="inputId"
        v-model="value"
        class="custom-input-input"
        @keyup="handleChange(errors)"
        @change="handleChange(errors)"
      />
      <component v-if="icon" class="custom-input-icon" :is="icon" />
      <component
        v-if="inputType === 'password'"
        class="custom-input-icon"
        :is="showPassword ? 'PasswordEyeShown' : 'PasswordEyeHidden'"
      />
    </ValidationProvider>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import {
  email,
  required,
  alpha_num,
  alpha,
  alpha_spaces,
} from 'vee-validate/dist/rules';
import {
  AlertIcon,
  ArrowRightIcon,
  PasswordEyeHidden,
  PasswordEyeShown,
} from '~/components/General/Icons';
import libphonenumber from 'google-libphonenumber';
import { useI18n } from '~/helpers/hooks/usei18n';

extend('email', {
  ...email,
  message: 'The email format is not correct',
});

extend('alpha_num', {
  ...alpha_num,
  message: 'May only contain alpha-numeric characters',
});

extend('required', {
  ...required,
  message: 'Required field',
});

extend('phone', {
  validate(value, args) {
    if (!args || !Array.isArray(args) || args.length === 0 || args[0] === '')
      return false;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      return phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(value, args[0]),
        args[0]
      );
    } catch {
      console.warn('Invalid phone number');
      return false;
    }
  },
});

export default defineComponent({
  name: 'CustomInput',
  components: {
    ValidationProvider,
    AlertIcon,
    ArrowRightIcon,
    PasswordEyeHidden,
    PasswordEyeShown,
  },
  props: {
    inputType: {
      type: String,
      default: 'text',
    },
    inputPlaceholder: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      required: true,
    },
    inputValue: {
      type: [String, null],
      default: null,
    },
    inputLabel: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      required: true,
    },
    validateRule: {
      type: [Boolean, String],
      default: '',
    },
    interactionMode: {
      type: String,
      default: 'aggressive',
    },
    icon: {
      type: [Boolean, String],
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    /**
     * Defines a CSS class name based on the props passed to it.
     * The class name is used to style the component.
     * The class name is constructed using a template literal that concatenates the string "custom-input" with the value of the "inputType" prop.
     * If the "inputLabel" prop is not an empty string, the string "custom-input-with-label" is appended to the class name.
     * Otherwise, the string "custom-input-without-label" is appended to the class name.
     * The resulting class name is assigned to the "containerClasses" constant.
     */
    const containerClasses = `custom-input custom-input-${props.inputType}${
      props.inputLabel !== ''
        ? ' custom-input-with-label'
        : ' custom-input-without-label'
    }`;
    const value = ref(props.inputValue);
    const showPassword = ref(false);
    const customInputRef = ref(null);

    /**
     * Toggles the visibility of a password input field in a Vue component.
     * It does this by changing the value of a showPassword variable
     * and then setting the type attribute of a customInputRef element to either 'text' or 'password' depending on the value of showPassword.
     */
    const handleShowPassword = () => {
      showPassword.value = !showPassword.value;
      customInputRef.value.type = showPassword.value ? 'text' : 'password';
    };

    /**
     * The selected code is a Vue.js computed property called isEmail.
     * It returns a boolean value that is true if the validateRule prop includes the string 'email' or if the inputType prop is equal to 'email'.
     * This computed property is used to determine if the input field should be validated as an email input.
     */
    const isEmail = computed(
      () =>
        (props.validateRule && props.validateRule.includes('email')) ||
        props.inputType === 'email'
    );

    /**
     * Triggered when the input value of the component changes.
     * It emits an event called inputChange with the value of the input as an argument.
     * The value is first checked if it is a valid email address and if it is,
     * it is converted to lowercase before being passed as an argument to the inputChange event.
     */
    const handleChange = () => {
      emit(
        'inputChange',
        value.value && isEmail.value ? value.value.toLowerCase() : value.value
      );
    };

    const trans = useI18n();

    /**
     * The selected code is a Vue.js validation rule that checks if the input value is longer than a specified length.
     * The rule is defined using the extend method provided by the VeeValidate library.
     * The validate function takes two arguments: the input value v and an object containing the validation arguments args.
     * The function returns true if the length of the input value is greater than or equal to the specified length, and false otherwise.
     * The params property is an array of strings that define the names of the validation arguments.
     * The message property is a function that returns a validation error message.
     * In this case, the message is generated using a translation function trans.t that takes an array of arguments.
     */
    extend('min', {
      validate: (v, args) => v.length >= args.length,
      params: ['length'],
      message: (_, args) =>
        trans.t(`Must be longer than than {0} characters`, [args.length]),
    });

    extend('max', {
      validate: (v, args) => v.length < args.length,
      params: ['length'],
      message: (_, args) =>
        trans.t(`Must be shorter than {0} characters.`, [args.length]),
    });

    extend('only-english-characters', {
      validate: (v) => /^[\s\w!"#$%&'()*+,./:;<=>?@[\]^`{|}~-]+$/.test(v),
      message: () => trans.t(`Sorry, only Latin characters are allowed.`),
    });

    extend('alpha', {
      ...alpha,
      message: trans.t('May only contain alphabetic characters'),
    });

    extend('alpha_spaces', {
      ...alpha_spaces,
      message: trans.t('May only contain alphabetic characters'),
    });

    return {
      containerClasses,
      value,
      handleChange,
      showPassword,
      customInputRef,
      handleShowPassword,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-input {
  height: 3.125rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  input {
    border: none;
    padding: 0;
    height: 100%;
    outline: var(--black-border);
    border-radius: var(--border-radius);
    padding-left: 1.25rem;
    @include font-14x14;
    @include to-portrait-max {
      font-size: 1rem;
    }
    color: var(--c-black);
    &::placeholder {
      color: var(--c-grey-2);
    }
    &.has-errors {
      outline-color: var(--c-red-error);
      &:focus-visible {
        border: none;
        outline-color: var(--c-red-error);
      }
    }
    &[type='date'] {
      padding-right: 1.25rem;
    }
  }
  &-with-label {
    height: 4.625rem;
    .custom-input-input {
      height: 3.125rem;
    }
  }
  &-without-label {
    input {
      height: 3.125rem;
    }
  }
  &-full-width {
    width: 100%;
  }
  &-disabled {
    input {
      background-color: var(--c-super-light-grey-2);
      pointer-events: none;
    }
  }
  .custom-input-label {
    @include font-14x16;
  }
  .custom-input-error {
    @include font-12x16;
  }
  .custom-input-container {
    display: grid;
    align-items: center;
    row-gap: 0.313rem;
    width: 100%;
    grid-template-columns: repeat(100, 1fr);
    .custom-input-input {
      grid-row: 1 / span 2;
      grid-column: 1 / span 100;
    }
    .custom-input-error {
      grid-row: 3;
      grid-column: 1 / span 100;
      padding-left: 1rem;
      color: var(--c-red-error);
    }
    .custom-input-icon {
      grid-row: 1 / span 2;
      grid-column: 90 / span 95;
    }
  }
}
@include from-landscape-min {
  .custom-input {
    width: 100%;
  }
}
</style>
