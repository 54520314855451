<template>
  <div class="sf-gallery">
    <div class="sf-gallery__stage">
      <div ref="glide" class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides">
            <li
              v-for="(picture, index) in images"
              :key="'slide-' + index"
              class="glide__slide"
              @mouseover="startZoom(picture)"
              @mousemove="moveZoom($event, index)"
              @mouseout="removeZoom(index)"
            >
              <slot
                name="big-image"
                v-bind="{
                  enableZoom,
                  picture,
                  index,
                  imageWidth,
                  imageHeight,
                  imageTag,
                  nuxtImgConfig,
                }"
              >
                <SfImage
                  ref="sfGalleryBigImage"
                  class="sf-gallery__big-image"
                  :class="{ 'sf-gallery__big-image--has-zoom': enableZoom }"
                  :src="picture.desktop.url"
                  :alt="picture.alt || `alt_${index}`"
                  :placeholder="picture.placeholder"
                  :width="imageWidth"
                  :height="imageHeight"
                  :image-tag="imageTag"
                  :nuxt-img-config="nuxtImgConfig"
                  :loading="index === 0 ? 'eager' : 'lazy'"
                  @click="$emit('click:stage', { picture, index })"
                />
              </slot>
            </li>
          </ul>
        </div>
      </div>
      <transition name="sf-fade">
        <div
          ref="outSide"
          :class="{
            'display-none':
              !outsideZoom || !isZoomStarted || (!outsideZoom && !enableZoom),
          }"
          :style="{ width: `${imageWidth}px`, height: `${imageHeight}px` }"
        >
          <slot
            name="outside-zoom"
            v-bind="{
              definedPicture,
              imageWidth,
              imageHeight,
              imageTag,
              nuxtImgConfig,
            }"
          >
            <SfImage
              ref="imgZoom"
              class="sf-gallery__zoom"
              :src="definedPicture.url"
              :width="imageWidth"
              :height="imageHeight"
              :lazy="false"
              :alt="definedPicture.alt || `alt`"
              :placeholder="definedPicture.placeholder"
              :image-tag="imageTag"
              :nuxt-img-config="nuxtImgConfig"
            />
          </slot>
        </div>
      </transition>
    </div>
    <div class="sf-gallery__thumbs">
      <slot name="thumbs" v-bind="{ images, active: activeIndex, go }">
        <SfButton
          v-for="(image, index) in images"
          :key="'img-' + index"
          class="sf-button--pure sf-gallery__item"
          :class="{ 'sf-gallery__item--selected': index === activeIndex }"
          :aria-label="'Image ' + index"
          @click="go(index)"
        >
          <SfImage
            class="sf-gallery__thumb"
            :src="image.mobile.url"
            :alt="image.alt || `alt_${index}`"
            :placeholder="image.placeholder"
            :width="thumbWidth"
            :height="thumbHeight"
            :image-tag="thumbImageTag"
            :nuxt-img-config="thumbNuxtImgConfig"
          />
        </SfButton>
      </slot>
    </div>
  </div>
</template>

<script>
import Glide from '@glidejs/glide';
import { SfButton, SfImage } from '@storefront-ui/vue';

export default {
  name: 'CustomGallery',
  components: {
    SfImage,
    SfButton,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    thumbWidth: {
      type: [Number, String],
      default: null,
    },
    thumbHeight: {
      type: [Number, String],
      default: null,
    },
    current: {
      type: Number,
      default: 1,
    },
    sliderOptions: {
      type: Object,
      default() {
        return {
          type: 'slider',
          autoplay: false,
          rewind: false,
          gap: 0,
        };
      },
    },
    outsideZoom: {
      type: Boolean,
      default: false,
    },
    enableZoom: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: 'img',
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    thumbImageTag: {
      type: String,
      default: 'img',
    },
    thumbNuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    imgLoading: {
      type: String,
      default: 'lazy',
    },
  },
  data() {
    return {
      positionStatic: {},
      eventHover: {},
      glide: null,
      activeIndex: this.current - 1,
      style: '',
      pictureSelected: this.images[0] || {
        alt: '',
        zoom: '',
        big: {
          url: '',
        },
        desktop: '',
        placeholder: '',
      },
      isZoomStarted: false,
    };
  },
  computed: {
    mapPictures() {
      // map images to handle picture tags with SfImage
      return this.images.map(({ desktop, big }) => ({
        mobile: desktop,
        desktop: big,
      }));
    },
    updatedSliderOptions() {
      return { ...this.sliderOptions, startAt: this.activeIndex };
    },
    definedPicture() {
      const { zoom, big, desktop, alt, placeholder } = this.pictureSelected;
      const definedPicture = zoom || big || desktop;
      definedPicture ? (definedPicture.alt = alt) : null;
      definedPicture ? (definedPicture.placeholder = placeholder) : null;
      return definedPicture || '';
    },
  },
  mounted() {
    this.$nextTick(() => {
      // handle slider with swipe and transitions with Glide.js
      // https://glidejs.com/docs/
      if (this.images.length === 0) return;
      const glide = new Glide(this.$refs.glide, this.updatedSliderOptions);
      glide.on('run', () => {
        this.go(glide.index);
      });
      glide.mount();
      this.glide = glide;
    });
  },
  updated() {
    if (this.glide) {
      this.$nextTick(() => {
        this.glide.mount();
      });
    }
  },
  beforeUnmount() {
    if (this.glide) {
      this.glide.destroy();
    }
  },
  methods: {
    positionObject(index) {
      if (this.$refs.sfGalleryBigImage) {
        if (this.outsideZoom) {
          return this.$refs.glide.getBoundingClientRect();
        }
        return this.$refs.sfGalleryBigImage[index].$el.getBoundingClientRect();
      }
      return '';
    },
    go(index) {
      this.pictureSelected = this.images[index];
      if (!this.glide) return;
      this.activeIndex = index;
      /**
       * Event for current image change (`v-model`)
       * @type {Event}
       */
      this.$emit('click', Number.parseInt(index, 10) + 1);
      if (this.glide) {
        this.glide.go(`=${index}`);
      }
    },
    startZoom() {
      if (this.enableZoom) {
        this.isZoomStarted = true;
      }
    },
    moveZoom($event, index) {
      if (this.enableZoom) {
        this.eventHover = $event;
        if (this.outsideZoom) {
          this.positionStatic = this.positionObject(index);
          this.$refs.outSide.style.cssText = `position: absolute; left: ${
            $event.clientX - this.positionStatic.x
          }px; top: ${$event.clientY - this.positionStatic.y}px; z-index: 1;`;
          this.$refs.imgZoom.$el.children[0].style.cssText = `transform: scale(2); width: 300px; height: auto;`;
          this.$refs.imgZoom.$el.children[0].style.transformOrigin = `${
            $event.clientX - this.positionStatic.x
          }px ${$event.clientY - this.positionStatic.y}px`;
        } else {
          this.positionStatic = this.positionObject(index);
          this.$refs.sfGalleryBigImage[index].$el.children[0].style.cssText =
            'top: 0; transform: scale(2);';
          this.$refs.sfGalleryBigImage[
            index
          ].$el.children[0].style.transformOrigin = `${
            $event.clientX - this.positionStatic.x
          }px ${$event.clientY - this.positionStatic.y}px`;
        }
      }
    },
    removeZoom(index) {
      if (this.enableZoom) {
        this.isZoomStarted = false;
        if (this.outsideZoom) return;
        this.$refs.sfGalleryBigImage[index].$el.children[0].style.transform =
          'scale(1)';
      }
    },
  },
};
</script>
