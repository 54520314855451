<template>
  <div class="custom-phone-number-input">
    <div v-if="inputLabel" class="custom-phone-number-input__label">
      {{ $t(inputLabel) }}
    </div>
    <VuePhoneNumberInput
      v-model="value"
      @update="handleChange"
      :id="inputId"
      :required="isRequired"
      :disabled="isDisabled"
      :error="error"
      :clearable="true"
      :no-example="true"
      :default-country-code="defaultFlag || countryCode"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  useContext,
  computed,
  onMounted,
} from '@nuxtjs/composition-api';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default defineComponent({
  name: 'CustomPhoneNumberInput',
  components: {
    VuePhoneNumberInput,
  },
  props: {
    isRequired: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      default: 'CustomPhoneNumberInput',
    },
    inputLabel: {
      type: String,
      default: 'Phone number',
    },
    defaultValue: {
      type: String,
      default: '',
    },
    defaultFlag: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const value = ref(props.defaultValue);
    const error = ref(false);
    const {
      route: {
        value: { path },
      },
    } = useContext();
    const countryCode = ref();

    const populateCountryCode = () => {
      if (typeof window !== 'undefined') {
        try {
          const countryFromLocalStorageString = window?.localStorage?.getItem(
            'vsf-country-selected'
          );
          const countryFromLocalStorage = countryFromLocalStorageString
            ? JSON.parse(
                countryFromLocalStorageString
              )?.countryIso?.toUpperCase()
            : null;
          if (countryFromLocalStorage) {
            countryCode.value = countryFromLocalStorage;
            return;
          }
        } catch (countrySelectedJSError) {
          console.warn(
            'Could not parse vsf-country-selected ~ error:',
            countrySelectedJSError
          );
        }
      }

      let countryCodeFromPath;
      try {
        // eslint-disable-next-line prefer-destructuring
        countryCodeFromPath = path.split('/')[1];
      } catch {
        countryCodeFromPath = null;
      }

      const countryCodeMapping = {
        row: 'AR',
        exu: 'AL',
        eue: 'BG',
        uae: 'AE',
        en: 'IT',
        eu: 'DK',
        uk: 'GB',
      };

      countryCode.value =
        countryCodeMapping[countryCodeFromPath] ||
        (countryCodeFromPath ? countryCodeFromPath.toUpperCase() : 'IT');
    };

    onMounted(() => {
      populateCountryCode();
    });

    const handleChange = (e) => {
      error.value = Boolean(
        !e ||
          (!e.isValid && e.formattedNumber) ||
          (props.isRequired && !e.formattedNumber)
      );
      emit('inputChange', !error.value ? e.formattedNumber : null);
      emit('validityChange', !error.value);
    };

    return {
      value,
      error,
      handleChange,
      countryCode,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-phone-number-input {
  &__label {
    @include font-14x16;
    margin-bottom: 0.5rem;
  }
}
</style>
