var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.validateRule)?_c('ValidationProvider',{attrs:{"rules":_vm.validateWithRequired},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var changed = ref.changed;
return [_c('SfCheckbox',{staticClass:"custom-checkbox",attrs:{"name":_vm.name,"required":_vm.validateRule && _vm.validateRule.includes('required'),"error-message":_vm.errorMessage,"hint-message":"","disabled":_vm.disabled,"selected":_vm.selected,"valid":changed ? _vm.selected === true : errors.length === 0},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.name !== 'consents')?_c('span',{staticClass:"custom-checkbox__label"},[_vm._v("\n        "+_vm._s(_vm.$t(_vm.label))+"\n        "),(_vm.name === 'privacy-policy-checkbox')?_c('SfLink',{attrs:{"link":_vm.$fixUrlForCurrentLocale(
              _vm.locale === 'it' ? 'privacy' : '/privacy-policy'
            ),"target":"_blank"}},[_vm._v("\n          Privacy Policy\n        ")]):_vm._e(),_vm._v(" "),(_vm.name === 'terms-and-conditions-checkbox')?_c('SfLink',{attrs:{"link":_vm.$fixUrlForCurrentLocale(
              _vm.locale === 'it' ? 'termini-condizioni' : '/terms-conditions'
            ),"target":"_blank"}},[_vm._v("\n          "+_vm._s(_vm.$t('Terms and conditions'))+"\n        ")]):_vm._e()],1):(_vm.name === 'consents')?_c('span',{staticClass:"custom-checkbox__label"},[_vm._v("\n        "+_vm._s(_vm.$t('Having read the customer and user'))+"\n        "),_c('SfLink',{attrs:{"link":_vm.$fixUrlForCurrentLocale(
              _vm.locale === 'it' ? 'privacy' : '/privacy-policy'
            ),"target":"_blank"}},[_vm._v("\n          "+_vm._s(_vm.$t('Privacy Policy'))+"\n        ")]),_vm._v("\n        ,"+_vm._s(_vm.$t(
            'I consent to receive informational and promotional communications via the contact methods I have chosen, which I can change at any time.'
          ))+"\n      ")],1):_vm._e()]},proxy:true},{key:"show-info-message",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.$t(_vm.infoMessage))+"\n      "),_c(_vm.icon,{tag:"component"})]},proxy:true},{key:"show-error-message",fn:function(){return [(errors.length > 0 || (changed && !_vm.selected))?_c('div',{staticClass:"custom-checkbox__error-message"},[_c('span',{staticClass:"custom-checkbox__error-icon"},[_c(_vm.icon,{tag:"component"})],1),_vm._v(" "),_c('span',{staticClass:"custom-checkbox__error-text"},[_vm._v("\n          "+_vm._s(_vm.$t(_vm.errorMessage))+"\n        ")])]):_c('div')]},proxy:true},{key:"checkmark",fn:function(){return [_c('div',{staticClass:"custom-checkbox__checkbox",class:{ 'is-selected': _vm.selected }},[_c('span',{staticClass:"custom-checkbox__checkmark"})])]},proxy:true}],null,true)})]}}],null,false,1950204979)}):_c('SfCheckbox',{staticClass:"custom-checkbox",attrs:{"name":_vm.name,"hint-message":"","disabled":_vm.disabled,"selected":_vm.selected},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"custom-checkbox__label"},[_vm._v("\n      "+_vm._s(_vm.$t(_vm.label))+"\n      "),(_vm.name === 'privacy-policy-checkbox')?_c('SfLink',{attrs:{"link":_vm.$fixUrlForCurrentLocale(
            _vm.locale === 'it' ? 'privacy' : '/privacy-policy'
          )}},[_vm._v("\n        Privacy Policy\n      ")]):_vm._e(),_vm._v(" "),(_vm.name === 'terms-and-conditions-checkbox')?_c('SfLink',{attrs:{"link":_vm.$fixUrlForCurrentLocale(
            _vm.locale === 'it' ? 'termini-condizioni' : '/terms-conditions'
          ),"target":"_blank"}},[_vm._v("\n        "+_vm._s(_vm.$t('Terms and conditions'))+"\n      ")]):_vm._e()],1)]},proxy:true},{key:"checkmark",fn:function(){return [_c('div',{staticClass:"custom-checkbox__checkbox",class:{ 'is-selected': _vm.selected }},[_c('span',{staticClass:"custom-checkbox__checkmark"})])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }