<template>
  <ValidationProvider
    v-if="validateRule"
    :rules="validateWithRequired"
    v-slot="{ errors, changed }"
  >
    <SfCheckbox
      :name="name"
      :required="validateRule && validateRule.includes('required')"
      :error-message="errorMessage"
      hint-message=""
      :disabled="disabled"
      :selected="selected"
      :valid="changed ? selected === true : errors.length === 0"
      @change="handleChange"
      class="custom-checkbox"
    >
      <template #label>
        <span class="custom-checkbox__label" v-if="name !== 'consents'">
          {{ $t(label) }}
          <SfLink
            v-if="name === 'privacy-policy-checkbox'"
            :link="
              $fixUrlForCurrentLocale(
                locale === 'it' ? 'privacy' : '/privacy-policy'
              )
            "
            target="_blank"
          >
            Privacy Policy
          </SfLink>
          <SfLink
            v-if="name === 'terms-and-conditions-checkbox'"
            :link="
              $fixUrlForCurrentLocale(
                locale === 'it' ? 'termini-condizioni' : '/terms-conditions'
              )
            "
            target="_blank"
          >
            {{ $t('Terms and conditions') }}
          </SfLink>
        </span>
        <span class="custom-checkbox__label" v-else-if="name === 'consents'">
          {{ $t('Having read the customer and user') }}
          <SfLink
            :link="
              $fixUrlForCurrentLocale(
                locale === 'it' ? 'privacy' : '/privacy-policy'
              )
            "
            target="_blank"
          >
            {{ $t('Privacy Policy') }}
          </SfLink>
          ,{{
            $t(
              'I consent to receive informational and promotional communications via the contact methods I have chosen, which I can change at any time.'
            )
          }}
        </span>
      </template>
      <template #show-info-message>
        {{ $t(infoMessage) }}
        <component :is="icon" />
      </template>
      <template #show-error-message>
        <div
          class="custom-checkbox__error-message"
          v-if="errors.length > 0 || (changed && !selected)"
        >
          <span class="custom-checkbox__error-icon">
            <component :is="icon" />
          </span>
          <span class="custom-checkbox__error-text">
            {{ $t(errorMessage) }}
          </span>
        </div>
        <div v-else />
      </template>
      <template #checkmark>
        <div
          class="custom-checkbox__checkbox"
          :class="{ 'is-selected': selected }"
        >
          <span class="custom-checkbox__checkmark" />
        </div>
      </template>
    </SfCheckbox>
  </ValidationProvider>
  <SfCheckbox
    v-else
    :name="name"
    hint-message=""
    :disabled="disabled"
    :selected="selected"
    @change="handleChange"
    class="custom-checkbox"
  >
    <template #label>
      <span class="custom-checkbox__label">
        {{ $t(label) }}
        <SfLink
          v-if="name === 'privacy-policy-checkbox'"
          :link="
            $fixUrlForCurrentLocale(
              locale === 'it' ? 'privacy' : '/privacy-policy'
            )
          "
        >
          Privacy Policy
        </SfLink>
        <SfLink
          v-if="name === 'terms-and-conditions-checkbox'"
          :link="
            $fixUrlForCurrentLocale(
              locale === 'it' ? 'termini-condizioni' : '/terms-conditions'
            )
          "
          target="_blank"
        >
          {{ $t('Terms and conditions') }}
        </SfLink>
      </span>
    </template>
    <template #checkmark>
      <div
        class="custom-checkbox__checkbox"
        :class="{ 'is-selected': selected }"
      >
        <span class="custom-checkbox__checkmark" />
      </div>
    </template>
  </SfCheckbox>
</template>

<script>
import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { SfCheckbox, SfLink } from '@storefront-ui/vue';
import { AlertIcon } from '~/components/General/Icons';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { useI18n } from '~/helpers/hooks/usei18n';

extend('required', {
  ...required,
  message: 'Required field',
});

export default defineComponent({
  name: 'CustomCheckbox',
  components: {
    SfCheckbox,
    SfLink,
    AlertIcon,
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      default: 'I have read and agree to the ',
    },
    name: {
      type: String,
      default: 'privacy-policy-checkbox',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    infoMessage: {
      type: String,
      default: 'Test Info Message',
    },
    errorMessage: {
      type: String,
      default: 'It is necessary to consent in order to proceed',
    },
    validateRule: {
      type: String,
      default: '',
    },
    selectedValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selected = ref(props.selectedValue);
    const { locale } = useI18n();

    const validateWithRequired = computed(() => {
      if (props.validateRule.includes('required')) {
        const validationObject = {};
        props.validateRule.split('|').forEach((e) => {
          if (e === 'required') {
            validationObject[e] = { allowFalse: false };
          } else if (e === '') {
            validationObject[e] = { allowFalse: true };
          } else {
            validationObject[e] = true;
          }
        });
        return validationObject;
      }
      return props.validateRule;
    });

    const handleChange = (e) => {
      selected.value = e;
      emit('checkboxChange', e);
    };

    return {
      selected,
      handleChange,
      icon: 'AlertIcon',
      validateWithRequired,
      locale,
    };
  },
});
</script>

<style lang="scss">
.custom-checkbox {
  width: fit-content;

  .sf-checkbox {
    &__container {
      gap: 1rem;
      .custom-checkbox {
        &__label {
          max-width: 20.25rem;
          @include font-14x19;
        }
        &__checkbox {
          display: var(--checkbox-display, flex);
          align-items: center;
          justify-content: center;
          order: var(--checkbox-order);
          width: var(--checkbox-size);
          min-width: 1.063rem;
          height: var(--checkbox-size);
          outline: var(--black-border);
          &.is-selected {
            .custom-checkbox__checkmark {
              width: 0.563rem;
              height: 0.563rem;
              background-color: var(--c-black);
            }
          }
        }
      }
    }
    &__message {
      .custom-checkbox__error-message {
        display: flex;
        gap: 1rem;
        .custom-checkbox__error {
          &-icon {
            width: var(--checkbox-size, 1.5rem);
            @include flex-center;
          }
          &-text {
            color: var(--c-red-error);
            @include font-12x16;
          }
        }
      }
    }
  }

  &.has-error {
    .sf-checkbox__container {
      .custom-checkbox__checkbox {
        outline: var(--red-border);
      }
    }
  }
}
</style>
