<template>
  <SfRadio
    :label="label"
    :value="value"
    :selected="selected && selected"
    :name="name"
    @change="handleChange(value)"
    class="custom-radio-button"
    :class="{ 'full-height': fullHeight }"
    :id="elementId"
    :disabled="disabled"
  >
    {{ label }}
    <template #description>
      <slot name="content" />
    </template>
  </SfRadio>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfRadio } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CustomRadio',
  components: {
    SfRadio,
  },
  props: {
    label: {
      type: String,
      default: 'Test Label',
    },
    value: {
      type: String,
      default: 'test-value',
    },
    name: {
      type: String,
      default: 'test-name',
    },
    elementId: {
      type: String,
      default: 'test-id',
    },
    selected: {
      type: String || null,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const selectedMethod = ref(null);
    const handleChange = (value) => {
      selectedMethod.value = value;
      emit('radioChange', selectedMethod.value);
    };

    return {
      handleChange,
      selectedMethod,
    };
  },
});
</script>

<style lang="scss">
.custom-radio-button {
  width: fit-content;
  height: 1.188rem;
  padding: 1px;
  .sf-radio {
    &__container {
      width: 100%;
      height: 100%;
      padding: 0;
      .sf-radio__checkmark {
        width: 1.063rem;
        height: 1.063rem;
        transition: none;
        border: var(--black-border);
        &.is-active {
          border: 0.25rem solid var(--c-white);
          background-color: var(--c-black);
          outline: var(--black-border) !important;
        }
      }
    }
    &__content {
      margin: 0 0 0 0.625rem;
      .sf-radio {
        &__label {
          @include font-14x19;
        }
      }
    }
  }
  &.is-active {
    background: var(--c-white);
  }
  &.full-height {
    height: 100%;
  }
}
</style>
