var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isSubmit)?_c('button',{class:'custom-button custom-button-' +
    _vm.type +
    (_vm.disabled ? ' disabled' : '') +
    (!_vm.isUnderlined ? ' ununderlined' : ''),style:(_vm.weight !== '' ? { fontWeight: _vm.weight } : {}),on:{"click":_vm.handleClick}},[(_vm.link !== '')?_c('SfLink',{attrs:{"link":_vm.link !== '#'
        ? _vm.$fixUrlForCurrentLocale(_vm.link)
        : _vm.$fixUrlForCurrentLocale('/#')}},[_vm._v("\n    "+_vm._s(_vm.text)+"\n  ")]):_c('span',[_vm._v(" "+_vm._s(_vm.text)+" ")])],1):_c('button',{class:'custom-button custom-button-' + _vm.type + (_vm.disabled ? ' disabled' : ''),attrs:{"type":"submit"}},[(_vm.link !== '')?_c('SfLink',{attrs:{"link":_vm.link !== '#'
        ? _vm.$fixUrlForCurrentLocale(_vm.link)
        : _vm.$fixUrlForCurrentLocale('/#')}},[_vm._v("\n    "+_vm._s(_vm.text)+"\n  ")]):_c('span',[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }