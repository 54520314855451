<template>
  <div class="custom-select" v-if="optionsArray">
    <label
      :for="selectName"
      v-if="selectLabel !== ''"
      class="custom-select-label"
    >
      {{ `${selectLabel} ${isRequired && isRequiredFormatted ? '*' : ''}` }}
    </label>
    <span v-if="countrySwitcher" class="custom-select-label__country">
      {{ $t("Can't find your country?") + ' ' }}
      <span @click="toggleCountrySidebar">{{ $t('Click here') }}</span>
    </span>
    <ValidationProvider
      :rules="isRequired ? 'required' : ''"
      v-slot="{ errors }"
      class="custom-select__container"
      :class="disabled && 'custom-select__container__disabled'"
    >
      <select
        :name="selectName"
        :id="selectName"
        class="custom-select__select"
        @change="handleChange"
        :value="selectValue || (defaultValue && defaultValue.value)"
      >
        <option
          v-for="(option, index) in selectOptions"
          :value="option.value"
          :key="option.value + index"
        >
          {{ option.label || option.value }}
        </option>
      </select>
      <component
        v-if="selectIcon"
        class="custom-select__icon"
        :is="selectIcon"
      />
      <span class="custom-select__error">
        {{ $t(errors[0]) }}
      </span>
    </ValidationProvider>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount } from '@nuxtjs/composition-api';
import { ChevronBlackSmallDownIcon } from '~/components/General/Icons';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { useUiState } from '~/composables';

extend('required', {
  ...required,
  message: 'Required field',
});
/**
 * This component needs a name and an array of objects to work:
 *
 * <CustomSelect select-name="customSelect" :select-options="selectOptions" />
 * const selectOptions = [
 *    {
 *      value: 'value1',
 *      label: 'label1',
 *    },
 *    {
 *      value: 'value2',
 *    },
 *  ];
 *
 * the objects need at least a value
 * if no label is provided, value gets used in its place
 **/
export default defineComponent({
  name: 'CustomSelect',
  components: {
    ChevronBlackSmallDownIcon,
    ValidationProvider,
  },
  props: {
    selectName: {
      type: String,
      required: true,
    },
    selectOptions: {
      type: Array,
      required: true,
    },
    selectIcon: {
      type: [Boolean, String],
      default: 'ChevronBlackSmallDownIcon',
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    selectLabel: {
      type: String,
      default: 'Test Label',
    },
    selectedValue: {
      type: [String, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isRequiredFormatted: {
      type: Boolean,
      default: true,
    },
    countrySwitcher: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const optionsArray = ref(props.selectOptions);
    const selectValue = ref(props.selectedValue);
    const defaultValue = ref();

    const { toggleCountrySidebar } = useUiState();

    onBeforeMount(() => {
      optionsArray.value = props.selectOptions
        .map((e) => {
          // eslint-disable-next-line no-prototype-builtins
          if (!e.hasOwnProperty('label')) {
            e.label = e.value;
          }
          // eslint-disable-next-line no-prototype-builtins
          if (e.hasOwnProperty('default')) {
            defaultValue.value = e;
            return null;
          }
          return e;
        })
        .filter(Boolean);
    });

    const handleChange = (e) => {
      selectValue.value = e.target.value;
      emit('selectChange', e.target.value);
    };
    return {
      optionsArray,
      selectValue,
      handleChange,
      defaultValue,
      toggleCountrySidebar,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-select__container {
  width: 22.313rem;
  height: 3.125rem;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(100, 1fr);
  row-gap: 0.313rem;
  .custom-select__select {
    width: 100%;
    height: 3.125rem;
    padding: 0 1.25rem;
    border: none;
    color: var(--c-text);
    outline: var(--black-border);
    border-radius: var(--border-radius);
    background-color: var(--c-white);
    @include font-14x14;
    &__no-option-selected,
    &::placeholder {
      color: var(--c-grey-2);
    }
    appearance: none;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 0.313rem;
    grid-column: 1 / span 100;
    grid-row: 1 / 2;
  }
  .custom-select__icon {
    grid-column: 92 / span 95;
    grid-row: 1 / 2;
  }
  .custom-select__error {
    grid-row: 2;
    grid-column: 1 / span 100;
    @include font-12x16;
    padding-left: 1.25rem;
    color: var(--c-red-error);
  }
  #quantity {
    z-index: 1;
    background-color: transparent;
  }
  &__disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
.custom-select-label {
  @include font-14x16;

  &__country {
    @include font-12x16;
    float: right;
    line-height: 1.6rem;
    font-weight: 300;

    span {
      text-decoration: underline;
      font-weight: 400;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
