<template>
  <button
    v-if="!isSubmit"
    @click="handleClick"
    :class="
      'custom-button custom-button-' +
      type +
      (disabled ? ' disabled' : '') +
      (!isUnderlined ? ' ununderlined' : '')
    "
    :style="weight !== '' ? { fontWeight: weight } : {}"
  >
    <SfLink
      v-if="link !== ''"
      :link="
        link !== '#'
          ? $fixUrlForCurrentLocale(link)
          : $fixUrlForCurrentLocale('/#')
      "
    >
      {{ text }}
    </SfLink>
    <span v-else> {{ text }} </span>
  </button>
  <button
    v-else
    type="submit"
    :class="
      'custom-button custom-button-' + type + (disabled ? ' disabled' : '')
    "
  >
    <SfLink
      v-if="link !== ''"
      :link="
        link !== '#'
          ? $fixUrlForCurrentLocale(link)
          : $fixUrlForCurrentLocale('/#')
      "
    >
      {{ text }}
    </SfLink>
    <span v-else> {{ text }} </span>
  </button>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CustomButton',
  components: {
    SfLink,
  },
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
    weight: {
      type: String,
      default: '',
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    isUnderlined: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const handleClick = () => {
      if (!props.disabled && props.link === '') emit('click');
    };

    return { handleClick };
  },
});
</script>

<style lang="scss" scoped>
.custom-button {
  &.custom-button-primary,
  &.custom-button-secondary,
  &.custom-button-tertiary {
    @include font-14x16;
    &:hover {
      cursor: pointer;
    }
  }

  &.custom-button-primary,
  &.custom-button-secondary {
    text-transform: uppercase;

    @include flex-center;
    font-weight: var(--font-weight-medium);
    width: 100%;
    height: var(--button-height);
    border-radius: var(--border-radius);
  }

  &.custom-button-primary {
    background-color: var(--c-black);
    color: var(--c-white);
    border: none;
    &:hover {
      opacity: 0.8;
    }
    &.disabled {
      background: var(--c-grey);
      pointer-events: none;
      opacity: 1;
    }
  }

  &.custom-button-secondary {
    background-color: var(--c-white);
    color: var(--c-black);
    border: var(--black-border);
    &:hover {
      border-color: var(--c-grey);
    }
  }

  &.custom-button-tertiary {
    width: fit-content;
    position: relative;
    font-weight: var(--font-weight-medium);
    &:not(.ununderlined)::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 0.125rem;
      bottom: 0;
      left: 0;
      background-color: #000;
      transform: scaleX(1);
      transform-origin: top right;
      transition: transform 0.3s ease;
    }
    &:hover::before {
      transform: scaleX(0);
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
</style>

<style lang="scss">
.custom-button {
  a.sf-link {
    all: unset;
  }
}
</style>
